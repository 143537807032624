<template>
      <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12" align="center" justify="center">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <v-btn color="secondary" @click="endCall()" x-large>
        <v-icon class="pa-2">mdi-phone-hangup</v-icon>
        End Call
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
    name: 'videoCall',
    mounted(){
        //
    }
}
</script>